@font-face {
  font-family:blue-goblet;
  src: url('../../public/images/BlueGobletBold.otf');
}

*:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
  --font: blue-goblet;
  --font-alt: blue-goblet-alt;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: var(--font);
}

body {
  margin: 0;
  font-family:var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:var(--font),
    monospace;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #B79CB0;
}

body::-webkit-scrollbar-thumb {
  background-color:#2c4774;
  border-radius: 50px;
}