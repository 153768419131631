.mint {
  display: none;
}
.displayMint {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#mint {
  --header-height: 88px;
  height: auto;
  max-width: 400px;
  aspect-ratio: 1/1.8;
  padding: 10px;
  position: relative;
  margin-top: 10px;
  background: transparent url(../../public/images/GT-phone-bg.png) no-repeat
    center center/100% 100%;
  transform: rotate(-2deg);
  padding: 40px 26px;
  filter: drop-shadow(8px 12px 0 rgba(0, 0, 0, 0.125));
  margin-left: auto;
  margin-right: auto;
}

#mint > div {
  background: transparent url(../../public/images/bgphone1x.png) no-repeat
    center center/100% 100%;
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#mint > div > div {
  width: 100%;
}
.PhoneButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 16px 24px 20px 14px;
  width: 100%;
  background: transparent url(../../public/images/button-bg.png) no-repeat
    center center/100% 100%;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.15s;
  font-weight: 800;
  font-size: 22px;
  text-decoration: none;
  color: #0e0e0e;
}

.PhoneButton:hover {
  transform: scale(1.025);
  filter: drop-shadow(0px 3px 10px #1a1a1a);
  -webkit-filter: drop-shadow(0px 3px 10px #0e0e0e);
  font-weight: 900;
  text-decoration: none;
  color: #0e0e0e;
}
.title__gif {
  width: 100%;
}

#Actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px auto 0;
}
.note {
  width: 100%;
  /* max-width: 450px; */
  margin: 30px 0px;
  font-weight: 800;
  font-size: clamp(25px, 3vmin, 22px);
  line-height: 1.125;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background: white;
  border-radius: 8px 12px 11px 8px;
  border: 2px solid black;
  transform: rotate(-1deg);
}
.note header,
.note main,
.note footer {
  padding: 16px;
}
.divide {
  height: 3px;
  width: calc(100% - 32px);
  margin: 0 auto;
}
.note main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}
.nft__img {
  border-radius: 30px;
  width: 100%;
}
#Follow {
  padding: 8px;
  text-align: Center;
  font-size: 28px;
  font-weight: 700;
  background: black;
  color: white;
}

@media screen and (max-width: 700px) {
  #mint {
    max-width: 90vw;
    height: auto;
  }
  .note {
    max-width: 85vw;
  }
  .nft__img {
    height: 250px;
    width: auto;
  }
}
@media screen and (max-width: 900px) {
  #mint {
    height: auto;
  }
  .note {
    max-width: 390px;
  }
  .nft__img {
    height: 300px;
    width: auto;
  }
}
